<template>
  <AppLoadingSheet v-if="!initialized" />

  <app-view-container v-else class="py-4">
    <app-error-alert v-if="showError" class="pa-4" />

    <ValidationObserver v-else ref="form" tag="form" @submit.prevent="onSubmit">
      <v-card-title class="text-h3 semi-bold px-6 pt-2 d-block">
        <partner-logos height="60px" width="60px" class="my-4" />
        <span v-if="unapprovedTermsAndConditions.length > 1" class="subtitle">
          {{
            $t('optIn.updateCountHint', {
              current: currentEntryIndex + 1,
              total: unapprovedTermsAndConditions.length
            })
          }}
        </span>
        <h2>
          {{ translations.headline }}
        </h2>
        <p class="pt-4" v-html="translations.info"></p>
      </v-card-title>

      <template v-if="hasAcceptPermission">
        <v-divider class="mt-2" />
        <v-card-text class="body-1 py-2 px-6">
          <template v-if="!isNewCreditor && currentEntry.changesHtml">
            <span class="opt-in__changes" v-html="currentEntry.changesHtml"></span>
            <v-divider class="mt-4 mb-2" />
          </template>

          <v-list>
            <template v-for="(document, index) in currentEntry.documents">
              <v-list-item :key="index" class="pa-0">
                <v-list-item-content class="pt-0">
                  <v-row dense align="center">
                    <v-col cols="auto" class="d-flex align-center mr-2">
                      <icon-vouchers height="25px" />
                    </v-col>
                    <v-col>
                      <app-text-btn
                        :loading="loadingFileType === document.type"
                        class="subtitle-1 semi-bold button-link--text text--underlined"
                        @click="openFile(document.source, document.type)"
                      >
                        {{ document.title }}
                      </app-text-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>

          <app-checkbox
            v-model="acceptTerms"
            :label="$t('optIn.acceptTerms')"
            required
            hide-details
          />
        </v-card-text>
      </template>

      <v-spacer />

      <v-divider />

      <v-card-actions class="px-6">
        <v-row>
          <v-col>
            <app-btn
              :title="hasAcceptPermission ? 'navigation.cancel' : 'navigation.ok'"
              block
              outlined
              @click="onCancel"
            />
          </v-col>
          <v-col v-if="hasAcceptPermission">
            <app-btn
              type="submit"
              block
              title="navigation.confirm"
              :loading="loading"
              :disabled="!acceptTerms"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </ValidationObserver>
  </app-view-container>
</template>

<script>
import OpenFileMixin from '@/mixins/OpenFileMixin';
import OptInStatus from '@/modules/Authentication/statics/OptInStatus';
import PartnerLogos from '@/shared/components/PartnerLogos';
import { NAMESPACE } from '@/modules/Authentication/store';
import { mapActions } from 'vuex';

export default {
  name: 'OptIn',

  mixins: [OpenFileMixin],

  components: { PartnerLogos },

  data: () => ({
    unapprovedTermsAndConditions: [],
    currentEntryIndex: 0,
    initialized: false,
    loading: false,
    showError: false,
    acceptTerms: false
  }),

  computed: {
    hasAcceptPermission() {
      return this.permissions.optIn.accept;
    },
    isNewCreditor() {
      return this.$auth.user().optIn === OptInStatus.NEW && this.currentEntryIndex === 0;
    },
    currentEntry() {
      return this.unapprovedTermsAndConditions[this.currentEntryIndex];
    },
    translations() {
      return {
        headline: this.$t(this.isNewCreditor ? 'optIn.new.headline' : 'optIn.update.headline'),
        info: this.$t(
          this.isNewCreditor && this.hasAcceptPermission
            ? 'optIn.new.infoCreditorUser'
            : this.isNewCreditor && !this.hasAcceptPermission
            ? 'optIn.new.infoCompanyUser'
            : !this.isNewCreditor && this.hasAcceptPermission
            ? 'optIn.update.infoCreditorUser'
            : !this.isNewCreditor && !this.hasAcceptPermission
            ? 'optIn.update.infoCompanyUser'
            : ''
        )
      };
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchUnapprovedTermsAndConditions', 'approveTermsAndConditions']),

    async getUnapprovedTermsAndConditions() {
      const { data, error } = await this.fetchUnapprovedTermsAndConditions();

      if (error) {
        this.showError = true;
        return;
      }

      if (data.length === 0) {
        this.showError = true;
        console.error('No unapproved terms and conditions found');
        return;
      }

      this.unapprovedTermsAndConditions = data;
    },

    async onCancel() {
      this.loading = true;
      await this.logout();
      this.loading = false;
    },

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const { error } = await this.approveTermsAndConditions(this.currentEntry.id);
      await this.$auth.fetch({});

      this.loading = false;

      if (error) {
        return;
      }

      if (this.currentEntryIndex + 1 < this.unapprovedTermsAndConditions.length) {
        this.acceptTerms = false;
        this.currentEntryIndex += 1;
        this.$refs.form?.reset();
        window.scrollTo(0, 0);
        return;
      }

      if (this.$auth.user() && this.$auth.user().passwordUpdateRequired) {
        return this.$router.replace({ name: this.routeName.UPDATE_PASSWORD });
      }

      this.$router.replace({ name: this.defaultRoute.route });
    }
  },

  async created() {
    await this.getUnapprovedTermsAndConditions();
    this.initialized = true;
  },

  async beforeRouteLeave(to, from, next) {
    if (this.$auth.user() && this.$auth.user().optIn !== OptInStatus.ACCEPTED) {
      await this.logout();
    }

    next();
  }
};
</script>

<style scoped lang="scss">
.opt-in {
  &__changes {
    white-space: pre-line;
  }
}
</style>
